export default [
  {
    name: "orderstore_index_stat",
    path: "/offline-stores/stats",
    component: () => import("../pages/global/syncunion/statistics.vue"),
  },
  {
    name: "orderstore_index",
    path: "/offline-stores/order",
    component: () => import("../pages/union/order/index.vue"),
  },
  {
    name: "promobank_list",
    path: "/offline-stores/promo-bank",
    component: () => import("../pages/union/promo-bank/index.vue"),
  },
  {
    name: "promobank_create",
    path: "/offline-stores/promo-bank/create",
    component: () => import("../pages/union/promo-bank/manage.vue"),
  },
  {
    name: "promobank_update",
    path: "/offline-stores/promo-bank/update/:id",
    component: () => import("../pages/union/promo-bank/manage.vue"),
  },
  {
    name: "tokovouchers_list",
    path: "/offline-stores/vouchers",
    component: () => import("../pages/union/vouchers/index.vue"),
  },
  {
    name: "tokovouchers_update",
    path: "/offline-stores/vouchers/update/:id",
    component: () => import("../pages/union/vouchers/update.vue"),
  },
  {
    name: "synctoko_index",
    path: "/offline-stores/sync-monitoring",
    component: () => import("../pages/global/syncunion/index.vue"),
  },
  {
    name: "store_location",
    path: "/offline-stores/store",
    component: () => import("../pages/website/store/index.vue"),
  },
];


