/*

    ==== JUST TEMPLATE DON'T DELETE IT ====

*/
import $axios from "../../api";

const state = () => ({
  isLoading: false,
  isErrors: "",
  page: 1,
  memberList: "",
  isSuccess: false,
  addressDetail: ""
});

const mutations = {
  SET_LOADING(state, payload) {
    state.isLoading = payload;
  },
  ASSIGN_ERRORS(state, payload) {
    state.isErrors = payload;
  },
  SET_IS_SUCCESS(state, payload) {
    state.isSuccess = payload;
  },
  SET_PAGE(state, payload) {
    state.page = payload;
  },
  ASSIGN_MEMBER(state, payload) {
    state.memberList = payload;
  },
  ASSIGN_ADDRESS_DETAIL(state, payload) {
    state.addressDetail = payload;
  },
};

const actions = {
  getMember({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(`reseller/member/list?page=${state.page}&view=${rootState.Main.view}${payload || ''}`)
        .then((res) => {
          commit("SET_LOADING", false);
          commit("ASSIGN_MEMBER", res.data.data)
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch('Main/globalSweetAlert', error.response, { root: true })
        });
    });
  },
  getMemberData({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(`reseller/member/view?uuid=${payload || ''}`)
        .then((res) => {
          commit("SET_LOADING", false);
          commit("ASSIGN_ADDRESS_DETAIL", res.data.data)
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch('Main/globalSweetAlert', error.response, { root: true })
        });
    });
  },
  createMember({ commit, state, rootState, dispatch }, payload) {
    commit("SET_IS_SUCCESS", false)
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .post(`reseller/member/creatememberaddress`, payload)
        .then((res) => {
          commit("SET_IS_SUCCESS", true)
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("ASSIGN_ERRORS", error.response.data.errors)
          commit("SET_IS_SUCCESS", false)
          commit("SET_LOADING", false);
          dispatch('Main/globalSweetAlert', error.response, { root: true })
        });
    });
  },
  updateMember({ commit, state, rootState, dispatch }, payload) {
    commit("SET_IS_SUCCESS", false)
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .post(`reseller/member/update`, payload)
        .then((res) => {
          commit("SET_IS_SUCCESS", true)
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("ASSIGN_ERRORS", error.response.data.errors)
          commit("SET_IS_SUCCESS", false)
          commit("SET_LOADING", false);
          dispatch('Main/globalSweetAlert', error.response, { root: true })
        });
    });
  },
  deleteMember({ commit, state, rootState, dispatch }, payload) {
    commit("SET_IS_SUCCESS", false)
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .post(`reseller/member/showhide`, payload)
        .then((res) => {
          commit("SET_IS_SUCCESS", true)
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("ASSIGN_ERRORS", error.response.data.errors)
          commit("SET_IS_SUCCESS", false)
          commit("SET_LOADING", false);
          dispatch('Main/globalSweetAlert', error.response, { root: true })
        });
    });
  },
  moveMember({ commit, state, rootState, dispatch }, payload) {
    commit("SET_IS_SUCCESS", false)
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .post(`reseller/member/move`, payload)
        .then((res) => {
          commit("SET_IS_SUCCESS", true)
          commit("SET_LOADING", false);
          dispatch('Main/globalSuccessSweetAlert', response.data.message, { root: true })
        })
        .catch((error) => {
          commit("ASSIGN_ERRORS", error.response.data.errors)
          commit("SET_IS_SUCCESS", false)
          commit("SET_LOADING", false);
          dispatch('Main/globalSweetAlert', error.response, { root: true })
        });
    });
  },
  hideShowMemberAddress({ commit, state, rootState, dispatch }, payload) {
    commit("SET_IS_SUCCESS", false)
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .post(`reseller/member/showhideaddress`, payload)
        .then((res) => {
          commit("SET_IS_SUCCESS", true)
          commit("SET_LOADING", false);
          dispatch('Main/globalSuccessSweetAlert', response.data.message, { root: true })
        })
        .catch((error) => {
          commit("SET_IS_SUCCESS", false)
          commit("SET_LOADING", false);
          dispatch('Main/globalSweetAlert', error.response, { root: true })
        });
    });
  },
  createNewAddress({ commit, state, rootState, dispatch }, payload) {
    commit("SET_IS_SUCCESS", false)
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .post(`memberaddress/create`, payload)
        .then((res) => {
          commit("SET_IS_SUCCESS", true)
          commit("SET_LOADING", false);
          dispatch('Main/globalSuccessSweetAlert', response.data.message, { root: true })
        })
        .catch((error) => {
          commit("SET_IS_SUCCESS", false)
          commit("SET_LOADING", false);
          commit("ASSIGN_ERRORS", error.response.data.errors)
          dispatch('Main/globalSweetAlert', error.response, { root: true })
        });
    });
  },
  updateAddress({ commit, state, rootState, dispatch }, payload) {
    commit("SET_IS_SUCCESS", false)
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .post(`memberaddress/update`, payload)
        .then((res) => {
          commit("SET_IS_SUCCESS", true)
          commit("SET_LOADING", false);
          dispatch('Main/globalSuccessSweetAlert', response.data.message, { root: true })
        })
        .catch((error) => {
          commit("SET_IS_SUCCESS", false)
          commit("SET_LOADING", false);
          commit("ASSIGN_ERRORS", error.response.data.errors)
          dispatch('Main/globalSweetAlert', error.response, { root: true })
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
