import $axios from "../../api";
import FileSaver from "file-saver";

const state = () => ({
  isLoading: false,
  isErrors: "",
  page: 1,
  orderList: [],
  orderSummary: 0,
  calculateData: "",
  otpNumber: "",
  isSuccess: false,
  checkoutSuccess: false,
  cart: [],
  orderData: null,
  orderCart: {},
  createMemberSuccess: false,
  orderDtwList: [],
});

const mutations = {
  SET_LOADING(state, payload) {
    state.isLoading = payload;
  },
  SET_CHECKOUT_SUCCESS(state, payload) {
    state.checkoutSuccess = payload;
  },
  ASSIGN_ERRORS(state, payload) {
    state.isErrors = payload;
  },
  SET_PAGE(state, payload) {
    state.page = payload;
  },
  ASSIGN_ORDER_LIST(state, payload) {
    state.orderList = payload;
  },
  ASSIGN_ORDER_SUMMARY(state, payload) {
    state.orderSummary = payload;
  },
  SET_OTP(state, payload) {
    state.otpNumber = payload;
  },
  SET_IS_SUCCESS(state, payload) {
    state.isSuccess = payload;
  },
  ASSIGN_CALCULATE(state, payload) {
    state.calculateData = payload;
  },
  ASSIGN_CART(state, payload) {
    state.cart = payload;
  },
  ASSIGN_ORDER_DATA(state, payload) {
    state.orderData = payload;
  },
  SET_CREATE_MEMBER_SUCCESS(state, payload) {
    state.createMemberSuccess = payload;
  },
  SET_ORDER_CART(state, payload) {
    state.orderCart = payload;
  },
  ASSIGN_DTW_LIST(state, payload) {
    state.orderDtwList = payload;
  },
};

const actions = {
  getAllProduct({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(
          `reseller/order/list?view=${rootState.Main.view}&page=${state.page
          }${payload ? payload : ""}`
        )
        .then((res) => {
          commit("ASSIGN_ORDER_LIST", res.data.data);
          commit("ASSIGN_ORDER_SUMMARY", res.data.summary);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  //   sendOtpToApprove({ commit, state, rootState, dispatch }, payload) {
  //     commit("SET_IS_SUCCESS", false)
  //     commit("SET_LOADING", true);
  //     return new Promise((resolve, reject) => {
  //       $axios
  //         .post(`reseller/order/approvalspg/insert`, payload)
  //         .then((res) => {
  //           commit("SET_IS_SUCCESS", true)
  //           commit("SET_LOADING", false);
  //         })
  //         .catch((error) => {
  //           commit("ASSIGN_ERRORS", error.response.data.errors)
  //           commit("SET_IS_SUCCESS", false)
  //           commit("SET_LOADING", false);
  //           dispatch('Main/globalSweetAlert', error.response, { root: true })
  //         });
  //     });
  //   },
  cancelOrder({ commit, state, rootState, dispatch }, payload) {
    commit("SET_IS_SUCCESS", false);
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .post(`reseller/order/delete`, payload)
        .then((res) => {
          commit("SET_IS_SUCCESS", true);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_IS_SUCCESS", false);
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  deleteOrder({ commit, state, rootState, dispatch }, payload) {
    commit("SET_IS_SUCCESS", false);
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .post(`reseller/order/delete`, payload)
        .then((res) => {
          commit("SET_IS_SUCCESS", true);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_IS_SUCCESS", false);
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  createNewCustomer({ commit, state, rootState, dispatch }, payload) {
    commit("SET_CREATE_MEMBER_SUCCESS", false);
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .post(`reseller/member/creatememberaddress`, payload)
        .then((res) => {
          commit("SET_CREATE_MEMBER_SUCCESS", true);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_CREATE_MEMBER_SUCCESS", false);
          commit("SET_LOADING", false);
          commit("ASSIGN_ERRORS", error.response.data.errors);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  paymentConfirm({ commit, state, rootState, dispatch }, payload) {
    commit("SET_IS_SUCCESS", false);
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .post(`reseller/paymentconfirm/create`, payload)
        .then((res) => {
          commit("SET_IS_SUCCESS", true);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_IS_SUCCESS", false);
          commit("SET_LOADING", false);
          commit("ASSIGN_ERRORS", error.response.data.errors);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  approvePayment({ commit, state, rootState, dispatch }, payload) {
    commit("SET_IS_SUCCESS", false);
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .post(`reseller/paymentconfirm/confirm`, payload)
        .then((res) => {
          commit("SET_IS_SUCCESS", true);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_IS_SUCCESS", false);
          commit("SET_LOADING", false);
          commit("ASSIGN_ERRORS", error.response.data.errors);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  calculateOrder({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    commit("SET_IS_SUCCESS", false);
    return new Promise((resolve, reject) => {
      $axios
        .post(`reseller/order/calculate`, state.orderCart)
        .then((res) => {
          commit("ASSIGN_CALCULATE", res.data);
          commit("SET_IS_SUCCESS", true);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_IS_SUCCESS", false);
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  createOrder({ commit, state, rootState, dispatch }, payload) {
    commit("SET_CHECKOUT_SUCCESS", false);
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .post(`reseller/order/checkout`, payload)
        .then((res) => {
          commit("SET_CHECKOUT_SUCCESS", true);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_CHECKOUT_SUCCESS", false);
          commit("SET_LOADING", false);
          commit("ASSIGN_ERRORS", error.response.data.errors);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  getOrderData({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(`reseller/order/view?uuid=${payload ? payload : ""}`)
        .then((res) => {
          commit("ASSIGN_ORDER_DATA", res.data.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  updateOrder({ commit, state, rootState, dispatch }, payload) {
    commit("SET_IS_SUCCESS", false);
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .post(`reseller/order/update`, payload)
        .then((res) => {
          commit("SET_IS_SUCCESS", true);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_IS_SUCCESS", false);
          commit("SET_LOADING", false);
          commit("ASSIGN_ERRORS", error.response.data.errors);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  convertDtw({ commit, state, rootState, dispatch }, payload) {
    commit("SET_IS_SUCCESS", false);
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .post(`reseller/order-dtw/convert`, payload)
        .then((res) => {
          commit("SET_IS_SUCCESS", true);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_IS_SUCCESS", false);
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  exportDtw({ commit, state, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .post(`reseller/order-dtw/exportdtw`, payload, {
          responseType: "blob",
        })
        .then((response) => {
          FileSaver.saveAs(
            response.data,
            `Export_DTW_${new Date()}.zip`
          );
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  listDtw({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(
          `reseller/order-dtw/list?view=${rootState.Main.view}&page=${state.page
          }${payload ? payload : ""}`
        )
        .then((res) => {
          commit("ASSIGN_DTW_LIST", res.data.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  submitShipping({ commit, state, rootState, dispatch }, payload) {
    commit("SET_IS_SUCCESS", false);
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .post(`reseller/shippingr/create`, payload)
        .then((res) => {
          commit("SET_IS_SUCCESS", true);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("ASSIGN_ERRORS", error.response.data);
          commit("SET_IS_SUCCESS", false);
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  submitInfo({ commit, state, rootState, dispatch }, payload) {
    commit("SET_IS_SUCCESS", false);
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .post(`reseller/order/update-info`, payload)
        .then((res) => {
          commit("SET_IS_SUCCESS", true);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("ASSIGN_ERRORS", error.response.data);
          commit("SET_IS_SUCCESS", false);
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {root: true});
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
