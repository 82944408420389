export default [

  //================ product ================
  {
    name: "productr_index",
    path: "/reseller/product",
    component: () => import("../pages/global/product/index.vue"),
  },
  {
    name: "productr_create",
    path: "/reseller/product/create",
    component: () => import("../pages/global/product/create.vue"),
  },
  {
    name: "productr_duplicatevariant",
    path: "/reseller/product/duplicate-variant",
    component: () => import("../pages/global/product/singleVariant.vue"),
  },
  {
    name: "productrvariant_update",
    path: "/reseller/product/update-variant",
    component: () => import("../pages/global/product/singleVariant.vue"),
  },
  {
    name: "productrvariant_create",
    path: "/reseller/product/create-variant",
    component: () => import("../pages/global/product/singleVariant.vue"),
  },
  {
    name: "productr_update",
    path: "/reseller/product/update/:id",
    component: () => import("../pages/global/product/updateProduct.vue"),
  },
  // {
  //   name: "product_logstock",
  //   path: "/reseller/product/log-stock",
  //     component: ()=> import("../pages/global/product/logStock.vue")
  // },

  // ================ Order ================
  {
    name: "orderreseller_index",
    path: "/reseller/order",
    component: () => import("../pages/reseller/order/index.vue"),
  },
  {
    name: "orderreseller_create",
    path: "/reseller/order/create",
    component: () => import("../pages/reseller/order/manage.vue"),
  },
  // {
  //     name: "orderreseller_update",
  //     path: "/reseller/order/update/:id",
  //     component: ()=> import("../pages/reseller/order/manage.vue")
  // },
  {
    name: "orderreseller_exportdtw",
    path: "/reseller/order/export-dtw",
    component: () => import("../pages/reseller/order/exportDtw.vue"),
  },
  {
    name: "shippingr_index",
    path: "/reseller/order/shipping/:uuid",
    component: () => import("../pages/reseller/order/shipping.vue"),
  },

  // ================ Promotions ================
  // disc price
  {
    name: "promorprice_index",
    path: "/reseller/promotions/disc-price",
    component: () =>
      import("../pages/reseller/promotions/2discountPrice/index.vue"),
  },
  {
    name: "promorprice_create",
    path: "/reseller/promotions/disc-price/create",
    component: () =>
      import("../pages/reseller/promotions/2discountPrice/manage.vue"),
  },
  {
    name: "promorprice_update",
    path: "/reseller/promotions/disc-price/update/:id",
    component: () =>
      import("../pages/reseller/promotions/2discountPrice/manage.vue"),
  },
  // disc percent
  {
    name: "promorpercent_index",
    path: "/reseller/promotions/disc-percent",
    component: () =>
      import("../pages/reseller/promotions/2discountPercent/index.vue"),
  },
  {
    name: "promorpercent_create",
    path: "/reseller/promotions/disc-percent/create",
    component: () =>
      import("../pages/reseller/promotions/2discountPercent/manage.vue"),
  },
  {
    name: "promorpercent_update",
    path: "/reseller/promotions/disc-percent/update/:id",
    component: () =>
      import("../pages/reseller/promotions/2discountPercent/manage.vue"),
  },
  // disc percent double
  {
    name: "promorpercentd_index",
    path: "/reseller/promotions/disc-percent-double",
    component: () =>
      import(
        "../pages/reseller/promotions/2discountPercentDouble/index.vue"
      ),
  },
  {
    name: "promorpercentd_create",
    path: "/reseller/promotions/disc-percent-double/create",
    component: () =>
      import(
        "../pages/reseller/promotions/2discountPercentDouble/manage.vue"
      ),
  },
  {
    name: "promorpercentd_update",
    path: "/reseller/promotions/disc-percent-double/update/:id",
    component: () =>
      import(
        "../pages/reseller/promotions/2discountPercentDouble/manage.vue"
      ),
  },
  // buy 1 get 1
  {
    name: "promorbg_index",
    path: "/reseller/promotions/buy1-get1",
    component: () =>
      import("../pages/reseller/promotions/3buy1get1/index.vue"),
  },
  {
    name: "promorbg_create",
    path: "/reseller/promotions/buy1-get1/create",
    component: () =>
      import("../pages/reseller/promotions/3buy1get1/manage.vue"),
  },
  {
    name: "promorbg_update",
    path: "/reseller/promotions/buy1-get1/update/:id",
    component: () =>
      import("../pages/reseller/promotions/3buy1get1/manage.vue"),
  },
  // min buy get free
  {
    name: "promorminbg_index",
    path: "/reseller/promotions/min-buy-get-free",
    component: () =>
      import(
        "../pages/reseller/promotions/12minBuyGetFreeItem/index.vue"
      ),
  },
  {
    name: "promorminbg_create",
    path: "/reseller/promotions/min-buy-get-free/create",
    component: () =>
      import(
        "../pages/reseller/promotions/12minBuyGetFreeItem/manage.vue"
      ),
  },
  {
    name: "promorminbg_update",
    path: "/reseller/promotions/min-buy-get-free/update/:id",
    component: () =>
      import(
        "../pages/reseller/promotions/12minBuyGetFreeItem/manage.vue"
      ),
  },
  // min buy get free mix
  {
    name: "promorminbgmix_index",
    path: "/reseller/promotions/min-buy-get-free-mix",
    component: () =>
      import(
        "../pages/reseller/promotions/13minBuyGetMixVariant/index.vue"
      ),
  },
  {
    name: "promorminbgmix_create",
    path: "/reseller/promotions/min-buy-get-free-mix/create",
    component: () =>
      import(
        "../pages/reseller/promotions/13minBuyGetMixVariant/manage.vue"
      ),
  },
  {
    name: "promorminbgmix_update",
    path: "/reseller/promotions/min-buy-get-free-mix/update/:id",
    component: () =>
      import(
        "../pages/reseller/promotions/13minBuyGetMixVariant/manage.vue"
      ),
  },
  // min buy get free disc price
  {
    name: "promorminbgmixf_index",
    path: "/reseller/promotions/min-buy-get-free-disc-price",
    component: () =>
      import(
        "../pages/reseller/promotions/14minBuyGetFreeAndDiscPrice/index.vue"
      ),
  },
  {
    name: "promorminbgmixf_create",
    path: "/reseller/promotions/min-buy-get-free-disc-price/create",
    component: () =>
      import(
        "../pages/reseller/promotions/14minBuyGetFreeAndDiscPrice/manage.vue"
      ),
  },
  {
    name: "promorminbgmixf_update",
    path: "/reseller/promotions/min-buy-get-free-disc-price/update/:id",
    component: () =>
      import(
        "../pages/reseller/promotions/14minBuyGetFreeAndDiscPrice/manage.vue"
      ),
  },

  // ================ price group ================
  {
    name: "pricegroup_index",
    path: "/reseller/pricegroup",
    component: () => import("../pages/global/priceGroup/index.vue"),
  },
  // ================ user price group ================
  {
    name: "priceuser_index",
    path: "/reseller/priceuser",
    component: () => import("../pages/global/userPriceGroup"),
  },
  {
    name: "priceuser_create",
    path: "/reseller/priceuser/create",
    component: () => import("../pages/global/userPriceGroup/manage.vue"),
  },
  {
    name: "priceuser_update",
    path: "/reseller/priceuser/update/:id",
    component: () => import("../pages/global/userPriceGroup/manage.vue"),
  },
  // ================ member ================
  {
    name: "member_index",
    path: "/reseller/member",
    component: () => import("../pages/global/member/index.vue"),
  },
  {
    name: "member_create",
    path: "/reseller/member/create",
    component: () => import("../pages/global/member/create.vue"),
  },
  {
    name: "member_update",
    path: "/reseller/member/update/:uuid",
    component: () => import("../pages/global/member/update.vue"),
  },
];
