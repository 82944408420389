export default [
  
  // ================ user ================
  {
    name: "user_index",
    path: "/administrator/users",
    component: () => import("../pages/global/user/index.vue"),
  },
  {
    name: "user_create",
    path: "/administrator/users/create",
    component: () => import("../pages/global/user/manage.vue"),
  },
  {
    name: "user_update",
    path: "/administrator/users/update/:id",
    component: () => import("../pages/global/user/manage.vue"),
  },
  {
    name: "user_assign",
    path: "/administrator/users/assign-access/:id",
    component: () => import("../pages/global/user/assign.vue"),
  },
  {
    name: "user_log",
    path: "/administrator/users/log-activity",
    component: () => import("../pages/global/user/log.vue"),
  },

  // ================ module ================
  {
    name: "module_index",
    path: "/administrator/modules",
    component: () => import("../pages/global/modules/index.vue"),
  },
  {
    name: "module_create",
    path: "/administrator/modules/create",
    component: () => import("../pages/global/modules/manage.vue"),
  },
  {
    name: "module_update",
    path: "/administrator/module/update/:id",
    component: () => import("../pages/global/modules/manage.vue"),
  },

  //================ role ================
  {
    name: "role_index",
    path: "/administrator/role",
    component: () => import("../pages/global/role/index.vue"),
  },
  {
    name: "role_create",
    path: "/administrator/role/create",
    component: () => import("../pages/global/role/manage.vue"),
  },
  {
    name: "role_update",
    path: "/administrator/role/update/:id",
    component: () => import("../pages/global/role/manage.vue"),
  },
];