import $axios from "../../api";

const state = () => ({
  isLoading: false,
  page: 1,
  orderList: [],
  orderData: null,
  orderSummary: 0,
  isSuccess: false,
});

const mutations = {
  SET_LOADING(state, payload) {
    state.isLoading = payload;
  },
  ASSIGN_LIST(state, payload) {
    state.orderList = payload;
  },
  ASSIGN_ORDER_SUMMARY(state, payload) {
    state.orderSummary = payload;
  },
  ASSIGN_VIEW(state, payload) {
    state.orderData = payload;
  },
  SET_PAGE(state, payload) {
    state.page = payload;
  },
};

const actions = {
  getList({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(
          `order/store/list?view=${rootState.Main.view}&page=${state.page
          }${payload ? payload : ""}`
        )
        .then((res) => {
          commit("ASSIGN_LIST", res.data.data);
          commit("ASSIGN_ORDER_SUMMARY", res.data.summary);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  getView({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(`order/store/view?docentry=${payload ? payload : ""}`)
        .then((res) => {
          commit("ASSIGN_VIEW", res.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
