import $axios from "../../api";

const state = () => ({
  isLoading: false,
  isErrors: "",
  isSuccess: false,
  page: 1,
  list: [],
  dataView: {},
});

const mutations = {
  SET_LOADING(state, payload) {
    state.isLoading = payload;
  },
  ASSIGN_ERRORS(state, payload) {
    state.isErrors = payload;
  },
  SET_SUCCESS(state, payload) {
    state.isSuccess = payload;
  },
  SET_PAGE(state, payload) {
    state.page = payload;
  },
  SET_LIST(state, payload) {
    state.list = payload;
  },
  SET_VIEW(state, payload) {
    state.dataView = payload;
  },
};

const actions = {
  list({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    let query_str = payload;
    return new Promise((resolve, reject) => {
      $axios
        .get(`hr/jobs/list?page=` + state.page + "&" + query_str)
        .then((res) => {
          commit("SET_LIST", res.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  Create({ commit, state, rootState, dispatch }, payload) {
    commit("SET_SUCCESS", false);
    commit("SET_LOADING", true);
    let image = null;
    if (payload.image.dataUrl == null) {
      image = null;
    } else {
      image = payload.image.dataUrl;
    }
    let attr_sender = {
      name: payload.title,
      status: payload.statusJob,
      region: payload.jobLocation,
      experience: payload.experience,
      image: image,
      requirement: payload.requirement,
      responsibility: payload.responsibility,
      meta_title: payload.metaTitle,
      meta_desc: payload.metaDesc,
      is_resume: payload.fieldEnable.is_resume,
      is_photo: payload.fieldEnable.is_photo,
      is_photofull: payload.fieldEnable.is_photofull,
      is_instagram: payload.fieldEnable.is_instagram,
      is_facebook: payload.fieldEnable.is_facebook,
      is_tiktok: payload.fieldEnable.is_tiktok,
      is_twitter: payload.fieldEnable.is_twitter,
      note: payload.note
    }
    return new Promise((resolve, reject) => {
      $axios
        .post(`hr/jobs/create`, attr_sender)
        .then((res) => {
          commit("SET_SUCCESS", true);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_SUCCESS", false);
          commit("SET_LOADING", false);
          commit("ASSIGN_ERRORS", error.response.data.errors);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  View({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    let id = payload;
    return new Promise((resolve, reject) => {
      $axios
        .get(`hr/jobs/view?id=` + id)
        .then((res) => {
          commit("SET_VIEW", res.data.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  Update({ commit, state, rootState, dispatch }, payload) {
    commit("SET_SUCCESS", false);
    commit("SET_LOADING", true);
    let image = null;
    if (payload.image.dataUrl == null) {
      image = null;
    } else {
      image = payload.image.dataUrl;
    }
    let attr_sender = {
      id: payload.id,
      name: payload.title,
      status: payload.statusJob,
      region: payload.jobLocation,
      experience: payload.experience,
      image: image,
      requirement: payload.requirement,
      responsibility: payload.responsibility,
      meta_title: payload.metaTitle,
      meta_desc: payload.metaDesc,
      is_resume: payload.fieldEnable.is_resume,
      is_photo: payload.fieldEnable.is_photo,
      is_photofull: payload.fieldEnable.is_photofull,
      is_instagram: payload.fieldEnable.is_instagram,
      is_facebook: payload.fieldEnable.is_facebook,
      is_tiktok: payload.fieldEnable.is_tiktok,
      is_twitter: payload.fieldEnable.is_twitter,
      note: payload.note
    }
    return new Promise((resolve, reject) => {
      $axios
        .put(`hr/jobs/update`, attr_sender)
        .then((res) => {
          commit("SET_SUCCESS", true);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_SUCCESS", false);
          commit("SET_LOADING", false);
          commit("ASSIGN_ERRORS", error.response.data.errors);

          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  showHide({ commit, state, rootState, dispatch }, payload) {
    commit("SET_SUCCESS", false);
    commit("SET_LOADING", true);
    let attr_sender = {
      id: payload.id
    };
    return new Promise((resolve, reject) => {
      $axios
        .put(`hr/jobs/showhide`, attr_sender)
        .then((res) => {
          commit("SET_SUCCESS", true);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
};
export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
