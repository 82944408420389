import $axios from "../../api";
import FileSaver from "file-saver";

const state = {
  isLoading: false,
  createLoading: false,
  errors: "",
  isSuccess: false,
  employeeList: [],
  candidateList: [],
  logEmployee: [],
  employeeData: {},
  candidateData: {},
  employeeAccessData: {},
  page: 1,
  ipData: "Please Wait...",
};

const mutations = {
  SET_LOADING(state, payload) {
    state.isLoading = payload;
  },
  SET_ERRORS(state, payload) {
    state.errors = payload;
  },
  SET_SUCCESS(state, payload) {
    state.isSuccess = payload;
  },
  SET_EMPLOYEE_LIST(state, payload) {
    state.employeeList = payload;
  },
  SET_EMPLOYEE_DATA(state, payload) {
    state.employeeData = payload;
  },
  SET_CANDIDATE_DATA(state, payload) {
    state.candidateData = payload;
  },
  SET_PAGE(state, payload) {
    state.page = payload;
  },
  SET_CREATE_LOAD(state, payload) {
    state.createLoading = payload;
  },
  ASSIGN_CANDIDATE(state, payload) {
    state.candidateList = payload;
  },
};

const actions = {
  getEmployeeList({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(
          `hr/employee/list?page=${state.page}&view=${rootState.Main.view}${payload ? payload : ""
          }`
        )
        .then((response) => {
          commit("SET_EMPLOYEE_LIST", response.data.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch('Main/globalSweetAlert', error.response, { root: true })
        });
    });
  },
  getCandidateData({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(`hr/candidate/view?id=${payload ? payload : ""}`)
        .then((response) => {
          commit("SET_CANDIDATE_DATA", response.data.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, { root: true });
        });
    });
  },
  getEmployeeData({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(`hr/employee/view?id=${payload ? payload : ""}`)
        .then((response) => {
          commit("SET_EMPLOYEE_DATA", response.data.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch('Main/globalSweetAlert', error.response, { root: true })
        });
    });
  },
  createEmployeeData({ commit, state, dispatch }, payload) {
    commit("SET_SUCCESS", false);
    commit("SET_CREATE_LOAD", true);
    return new Promise((resolve, reject) => {
      $axios
        .post(`hr/employee/create`, payload)
        .then((response) => {
          commit("SET_CREATE_LOAD", false);
          commit("SET_SUCCESS", true);
        })
        .catch((error) => {
          commit("SET_CREATE_LOAD", false);
          commit("SET_SUCCESS", false);
          commit("SET_ERRORS", error.response.data.errors);
          dispatch('Main/globalSweetAlert', error.response, { root: true })
        });
    });
  },
  updateEmployeeData({ commit, state, dispatch }, payload) {
    commit("SET_SUCCESS", false);
    commit("SET_CREATE_LOAD", true);
    return new Promise((resolve, reject) => {
      $axios
        .put(`hr/employee/update`, payload)
        .then((response) => {
          commit("SET_CREATE_LOAD", false);
          commit("SET_SUCCESS", true);
        })
        .catch((error) => {
          commit("SET_ERRORS", error.response.data.errors);
          commit("SET_CREATE_LOAD", false);
          commit("SET_SUCCESS", false);
          dispatch('Main/globalSweetAlert', error.response, { root: true })
        });
    });
  },
  showhide({ commit, state, dispatch }, payload) {
    commit("SET_SUCCESS", false);
    return new Promise((resolve, reject) => {
      $axios
        .post(`hr/employee/showhide`, payload)
        .then((response) => {
          commit("SET_SUCCESS", true);
          dispatch('Main/globalSuccessSweetAlert', response.data.message, { root: true })
        })
        .catch((error) => {
          commit("SET_SUCCESS", false);
          dispatch('Main/globalSweetAlert', error.response, { root: true })
        });
    });
  },
  exportExcel({ commit, state, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(
          `employee/exportexcel?${payload ? payload : ""
          }`,
          { responseType: "blob" }
        )
        .then((response) => {
          FileSaver.saveAs(
            response.data,
            `Export_Data_Employee_Query=${payload ? payload : ""
            }.xlsx`
          );
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch('Main/globalSweetAlert', error.response, { root: true })
        });
    });
  },
  selectCandidate({ commit, state, rootState, dispatch }, payload) {
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(`hr/select/candidate?find=${payload ? payload : ""}`)
        .then((res) => {
          commit("ASSIGN_CANDIDATE", res.data.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
