import $axios from "../../api";
const state = () => ({
  isLoading: false,
  isSuccess: false,
  isErrors: "",
  page: 1,
  detail_user: [],
  list_userserver: [],
  list_database: [],
  list_databaseuser: [],
  list_database: [],
  list_dir: [],
  list_diruser: [],
  list_hostname: [],
  dataview: [],
});
const mutations = {
  SET_LOADING(state, payload) {
    state.isLoading = payload;
  },
  SET_LOADING_GENERATE(state, payload) {
    state.isLoadingGenerate = payload;
  },
  SET_LOADING_GET(state, payload) {
    state.isLoadingGet = payload;
  },
  SET_LOADING_SELECT(state, payload) {
    state.isLoadingSelect = payload;
  },
  ASSIGN_ERRORS(state, payload) {
    state.isErrors = payload;
  },
  SET_PAGE(state, payload) {
    state.page = payload;
  },
  SET_IS_SUCCESS(state, payload) {
    state.isSuccess = payload;
  },
  GET_DETAILUSER(state, payload) {
    state.detail_user = payload;
  },
  GET_LISTUSERSERVER(state, payload) {
    state.list_userserver = payload;
  },
  GET_LISTDATABASEUSER(state, payload) {
    state.list_databaseuser = payload;
  },
  GET_LISTDIRUSER(state, payload) {
    state.list_diruser = payload;
  },
  GET_LISTDATABASE(state, payload) {
    state.list_database = payload;
  },
  GET_LISTDIR(state, payload) {
    state.list_dir = payload;
  },
  GET_LISTHOSTNAME(state, payload) {
    state.list_hostname = payload;
  },
  GET_VIEWDATAUPDATE(state, payload) {
    state.dataview = payload;
  }
};

const actions = {
  _getDetailUser({ commit, state, rootState, dispatch }, payload) {
    let url = "server/detail-users?user_id=" + payload.user_id
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(url)
        .then((res) => {
          commit("GET_DETAILUSER", res.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  _getUserServer({ commit, state, rootState, dispatch }, payload) {

    let url = "server/list-users" + payload
    console.log(url);
    
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(url)
        .then((res) => {
          commit("GET_LISTUSERSERVER", res.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  _getDatabase({ commit, state, rootState, dispatch }, payload) {
    var query = payload;
    let url = "/server/listdb" + query
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(url)
        .then((res) => {
          commit("GET_LISTDATABASE", res.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  _getDatabaseUser({ commit, state, rootState, dispatch }, payload) {
    var id = payload.user_id;
    let url = "/server/listdb-users/" + id
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(url)
        .then((res) => {
          commit("GET_LISTDATABASEUSER", res.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  _getDir({ commit, state, rootState, dispatch }, payload) {
    var query = payload;
    let url = "/server/listdir" + query
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(url)
        .then((res) => {
          commit("GET_LISTDIR", res.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  _getHostname({ commit, state, rootState, dispatch }, payload) {
    // var query = payload;
    let url = "/server/list-hostname"
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(url)
        .then((res) => {
          commit("GET_LISTHOSTNAME", res.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  _getDirUser({ commit, state, rootState, dispatch }, payload) {
    var id = payload.user_id;
    let url = "/server/listdir-users/" + id
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .get(url)
        .then((res) => {
          commit("GET_LISTDIRUSER", res.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  _getViewUserUpdate({ commit, state, rootState, dispatch }, payload) {
    var id = payload;
    let url = "/server/view"
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .post(url, id)
        .then((res) => {
          commit("GET_VIEWDATAUPDATE", res.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  _save({ commit, state, rootState, dispatch }, payload) {
    let url = "/server/saveall-access"
    let sendAttr = payload;
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .post(url, sendAttr)
        .then((res) => {
          // commit("GET_LISTDIRUSER", res.data);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  _saveUpdateUser({ commit, state, rootState, dispatch }, payload) {
    let url = "/server/update"
    let sendAttr = payload;
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .post(url, sendAttr)
        .then((res) => {
          commit("SET_IS_SUCCESS", true);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  _saveCreateUser({ commit, state, rootState, dispatch }, payload) {
    let url = "/server/create"
    let sendAttr = payload;
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .post(url, sendAttr)
        .then((res) => {
          commit("SET_IS_SUCCESS", true);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  },
  _saveDeleteUser({ commit, state, rootState, dispatch }, payload) {
    let url = "/server/showhide"
    let sendAttr = payload;
    commit("SET_LOADING", true);
    return new Promise((resolve, reject) => {
      $axios
        .post(url, sendAttr)
        .then((res) => {
          commit("SET_IS_SUCCESS", true);
          commit("SET_LOADING", false);
        })
        .catch((error) => {
          commit("SET_LOADING", false);
          dispatch("Main/globalSweetAlert", error.response, {
            root: true,
          });
        });
    });
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};

