// # ============================ Group Server <===========
export default [
  {
    name: "userserver_index",
    path: "/server",
    component: () => import("../pages/server/Users/index.vue"),
  },
  {
    name: "userserver_index",
    path: "/server/update/:id",
    component: () => import("../pages/server/Users/updateAccess.vue"),
  },
  {
    name: "userserver_create",
    path: "/server/user/create",
    component: () => import("../pages/server/Users/create.vue"),
  },
  {
    name: "userserver_update",
    path: "/server/user/update/:id",
    component: () => import("../pages/server/Users/update.vue"),
  }
];